<div class="secondary-sidebar">
  <label class="sidebar-title">AI Tools</label>
  <a href="https://www.techselect.ai/search" target="_blank" class="link-layout"
    >AI Search</a
  >
  <a
    href="https://www.techselect.ai/app-technician-search"
    target="_blank"
    class="link-layout"
    >AI Technician Search</a
  >
  <a
    href="https://www.techselect.ai/app-technician-select"
    target="_blank"
    class="link-layout"
    >Tech Select</a
  >
  <a
    href="https://www.techselect.ai/app-chat-streaming?context=Technicians"
    target="_blank"
    class="link-layout"
    >AI Tech Help</a
  >
  <a
    href="https://www.techselect.ai/app-chat-streaming?context=Help"
    target="_blank"
    class="link-layout"
    >AI Help Desk Assistant
  </a>
</div>

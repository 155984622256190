import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {SiteLayoutComponent} from './layout/site-layout.component';
import {IconModule, PlaceholderModule, UIShellModule,} from 'carbon-components-angular';
import {httpInterceptorProviders} from './interceptors';
import {SettingsContentHeaderComponent} from './header/settings-content-header/settings-content-header.component';
import {FeatureFlagModule} from "../shared/directives/feature-flag/feature-flag.module";
import {
  FieldFlowPrimaryLayoutModule
} from "../../../projects/fieldflow-ui/src/lib/components/field-flow-primary-layout/field-flow-primary-layout.module";
import {GlobalCalloutModule} from "./layout/components/global-callout/global-callout.module";
import {MatIconModule} from "@angular/material/icon";
import {
  TicketSearchComponent
} from './layout/components/delivery-search/components/ticket-search/ticket-search.component';
import {
  ClientSearchComponent
} from './layout/components/delivery-search/components/client-search/client-search.component';
import {
  ContractSearchComponent
} from './layout/components/delivery-search/components/contract-search/contract-search.component';
import {DeliverySearchComponent} from './layout/components/delivery-search/delivery-search.component';
import {
  InfiniteScrollContainerModule
} from "../shared/components/infinite-scroll-container/infinite-scroll-container.module";
import {ScrollItemComponent} from './layout/components/delivery-search/components/scroll-item/scroll-item.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {LoadingHandlerModule} from "../shared/components/loading-handler/loading-handler.module";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {DashboardsSidebarComponent} from './layout/sidebars/dashboards-sidebar/dashboards-sidebar.component';
import {ReportsSidebarComponent} from './layout/sidebars/reports-sidebar/reports-sidebar.component';
import {SidebarOptionComponent} from './layout/components/sidebar-option/sidebar-option.component';
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import { AiToolsSidebarComponent } from './layout/sidebars/ai-tools-sidebar/ai-tools-sidebar.component';

@NgModule({
  declarations: [
    SiteLayoutComponent,
    SettingsContentHeaderComponent,
    TicketSearchComponent,
    ClientSearchComponent,
    ContractSearchComponent,
    DeliverySearchComponent,
    ScrollItemComponent,
    DashboardsSidebarComponent,
    ReportsSidebarComponent,
    SidebarOptionComponent,
    AiToolsSidebarComponent,
  ],
  imports: [
    CommonModule,
    PlaceholderModule,
    RouterModule,
    UIShellModule,
    IconModule,
    FeatureFlagModule,
    FieldFlowPrimaryLayoutModule,
    GlobalCalloutModule,
    MatIconModule,
    InfiniteScrollContainerModule,
    FormsModule,
    LoadingHandlerModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
  ],
  exports: [SiteLayoutComponent, SettingsContentHeaderComponent],
  providers: [httpInterceptorProviders],
})
export class CoreModule {
}

import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { GlobalCalloutComponent } from './components/global-callout/global-callout.component';
import { filter, Observable, Subject, takeUntil } from 'rxjs';
import { UserService } from '../../services/user/user.service';
import { Select, Store } from '@ngxs/store';
import { GeneralStateModel } from '../../state/general/models/general-state.model';
import { GeneralState } from '../../state/general/general.state';
import { SidebarMenuOption } from '../../../../projects/fieldflow-ui/src/lib/components/field-flow-sidebar/models/sidebar-menu-option';
import { DeliverySearchComponent } from './components/delivery-search/delivery-search.component';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UtilityService } from '../../services/utility/utility.service';
import { ResetDeliverySearch } from '../../state/delivery-search/delivery-search.state';
import { DashboardsSidebarComponent } from './sidebars/dashboards-sidebar/dashboards-sidebar.component';
import { ReportsSidebarComponent } from './sidebars/reports-sidebar/reports-sidebar.component';
import { SettingsDropdownOptionModel } from 'projects/fieldflow-ui/src/lib/components/field-flow-navbar/models/settings-dropdown-option.model';
import { FeatureFlagsService } from '../../services/feature-flags/feature-flags.service';
import { environment } from 'src/environments/environment';
import { AiToolsSidebarComponent } from './sidebars/ai-tools-sidebar/ai-tools-sidebar.component';
import { tokenize } from '@angular/compiler/src/ml_parser/lexer';

@Component({
  selector: 'app-site-layout',
  templateUrl: './site-layout.component.html',
  styleUrls: ['./site-layout.component.css'],
})
export class SiteLayoutComponent implements OnInit, OnDestroy {
  @ViewChild(GlobalCalloutComponent)
  globalCalloutComponent: GlobalCalloutComponent;
  componentDestroyed$ = new Subject<void>();
  @Select(GeneralState) generalState$!: Observable<GeneralStateModel>;
  // TODO: Update after released
  utilizeNativeCopilot: boolean = false;
  name: string = '';
  role: string = '';

  sidebarOptions: SidebarMenuOption[] = [];
  environmentName: string = '';
  options: SettingsDropdownOptionModel[] = [
    {
      route: '/general/settings',
      icon: 'settings',
      label: 'General',
    },
    {
      route: '/catalog/settings',
      icon: 'menu_book',
      label: 'Catalogs',
    },
    {
      route: '/deletetestdata',
      icon: 'mop',
      label: 'Test Data Cleanup',
    },
  ];

  private readonly testDataCleanupOption: SettingsDropdownOptionModel = {
    route: '/deletetestdata',
    icon: 'mop',
    label: 'Test Data Cleanup',
  };

  private readonly permissionsOptions: SettingsDropdownOptionModel[] = [
    {
      route: '/users/settings/user-management',
      icon: 'manage_accounts',
      label: 'Users',
    },
    {
      route: '/users/settings/manage-roles',
      icon: 'supervisor_account',
      label: 'Roles',
    },
  ];
  private readonly iacPermission: string = 'View Permission';

  constructor(
    private readonly userService: UserService,
    private readonly store: Store,
    private readonly router: Router,
    private readonly utilityService: UtilityService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly featureFlagService: FeatureFlagsService
  ) {}

  ngOnInit() {
    if (
      environment.environmentName === 'sandbox' ||
      environment.environmentName === 'local'
    ) {
      this.environmentName =
        environment.environmentName.charAt(0).toUpperCase() +
        environment.environmentName.slice(1);
    }
    this.sidebarOptions = [
      {
        icon: 'dashboard',
        title: 'Dashboards',
        subMenu: DashboardsSidebarComponent,
      },
      {
        icon: 'bar_chart_4_bars',
        title: 'Reports',
        subMenu: ReportsSidebarComponent,
      },
      {
        icon: 'psychiatry',
        title: 'Opportunities',
        route: '/sales/deal',
        token: 'opportunities',
      },
      {
        icon: 'quick_reorder',
        title: 'Delivery',
        token: 'delivery',
        subMenu: DeliverySearchComponent,
      },
      {
        icon: 'tools_ladder',
        title: 'Vendors',
        route: '/vendors',
        token: 'vendors',
      },
      {
        icon: 'people',
        title: 'Technicians',
        route: '/technicians',
        token: 'technicians',
      },
      {
        icon: 'person_search',
        title: 'AI Tools',
        subMenu: AiToolsSidebarComponent,
      },
      {
        icon: 'store',
        title: 'Clients',
        route: '/clients',
        token: 'clients',
      },
      {
        icon: 'contact_support',
        title: 'Help Desk',
        href: 'https://forms.office.com/pages/responsepage.aspx?id=uWOtB29BIEqBctj32nbWnajaH1CsbvtCvp-uWoCdLcNUQ1dXMVNUMDlXVFQxUlYxODFNTk81UEM2WiQlQCN0PWcu&route=shorturl',
      },
    ];

    // Determine if any delivery search items are present in the route. If not, clear the state.
    const matchTokens = ['delivery', 'ticket'];
    this.router.events
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter((e) => e instanceof NavigationEnd)
      )
      .subscribe(() => {
        const matchedRoutes = this.utilityService.getMatchingRoutes(
          this.activatedRoute.root,
          'sidebarToken',
          matchTokens
        );
        if (matchedRoutes.size === 0) {
          this.store.dispatch(new ResetDeliverySearch());
        }
      });

    this.generalState$
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter((state) => !!state.authState?.user)
      )
      .subscribe((state) => {
        this.name = state.authState!.user.displayName;
        this.role = state.authState!.user.roles[0]?.name;

        // Configure permission-based settings options.
        const usrPermissions: { id: number; name: string }[] =
          state.authState!.user.permissions;
        const userHasPermissionsToAccessIac = usrPermissions.some(
          (permission) => permission.name === this.iacPermission
        );
        const permissionOptions = this.options.filter((option) =>
          this.permissionsOptions.some(
            (permissionOption) => permissionOption.route === option.route
          )
        );
        if (userHasPermissionsToAccessIac && permissionOptions.length === 0) {
          this.options.push(...this.permissionsOptions);
        } else if (
          !userHasPermissionsToAccessIac &&
          permissionOptions.length > 0
        ) {
          this.options = this.options.filter(
            (option) =>
              !this.permissionsOptions.some(
                (permissionOption) => permissionOption.route === option.route
              )
          );
        }
      });

    this.featureFlagService
      .variation('show-delete-test-data', false)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((variation) => {
        // Index of the test data cleanup option. Used to determine if the option is already present in the list.
        const index = this.options.findIndex(
          (option) => option.route === this.testDataCleanupOption.route
        );

        if (variation && index === -1) {
          this.options.push(this.testDataCleanupOption);
        } else if (!variation && index !== -1) {
          this.options.splice(index, 1);
        }
      });
  }

  searchClicked(): void {
    if (this.utilizeNativeCopilot) {
      this.globalCalloutComponent.toggleVisibility();

      return;
    }

    window.open('https://www.techselect.ai/search', '_blank');
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  logOut() {
    this.userService.logout();
  }
}
